"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuditedSuperBlocks = exports.createFlatSuperBlockMap = exports.notAuditedSuperBlocks = exports.superBlockStages = exports.getStageOrder = exports.SuperBlockStage = exports.SuperBlocks = void 0;
// TODO: eventually this should all flow from the curriculum service, since it
// defines the top-level structure of the curriculum.
const i18n_1 = require("./i18n");
// all superblocks
var SuperBlocks;
(function (SuperBlocks) {
    SuperBlocks["RespWebDesignNew"] = "2022/responsive-web-design";
    SuperBlocks["RespWebDesign"] = "responsive-web-design";
    SuperBlocks["JsAlgoDataStruct"] = "javascript-algorithms-and-data-structures";
    SuperBlocks["JsAlgoDataStructNew"] = "javascript-algorithms-and-data-structures-v8";
    SuperBlocks["FrontEndDevLibs"] = "front-end-development-libraries";
    SuperBlocks["DataVis"] = "data-visualization";
    SuperBlocks["RelationalDb"] = "relational-database";
    SuperBlocks["BackEndDevApis"] = "back-end-development-and-apis";
    SuperBlocks["QualityAssurance"] = "quality-assurance";
    SuperBlocks["SciCompPy"] = "scientific-computing-with-python";
    SuperBlocks["DataAnalysisPy"] = "data-analysis-with-python";
    SuperBlocks["InfoSec"] = "information-security";
    SuperBlocks["MachineLearningPy"] = "machine-learning-with-python";
    SuperBlocks["CodingInterviewPrep"] = "coding-interview-prep";
    SuperBlocks["TheOdinProject"] = "the-odin-project";
    SuperBlocks["ProjectEuler"] = "project-euler";
    SuperBlocks["CollegeAlgebraPy"] = "college-algebra-with-python";
    SuperBlocks["FoundationalCSharp"] = "foundational-c-sharp-with-microsoft";
    SuperBlocks["FrontEndDevelopment"] = "front-end-development";
    SuperBlocks["UpcomingPython"] = "upcoming-python";
    SuperBlocks["A2English"] = "a2-english-for-developers";
    SuperBlocks["B1English"] = "b1-english-for-developers";
    SuperBlocks["RosettaCode"] = "rosetta-code";
    SuperBlocks["PythonForEverybody"] = "python-for-everybody";
})(SuperBlocks || (exports.SuperBlocks = SuperBlocks = {}));
/*
 * SuperBlockStages.New = SHOW_NEW_CURRICULUM === 'true'
 * 'New' -> shown only on english staging at the moment
 *
 * SuperBlockStages.Upcoming = SHOW_UPCOMING_CHANGES === 'true'
 * 'Upcoming' is for development -> not shown on stag or prod anywhere
 */
var SuperBlockStage;
(function (SuperBlockStage) {
    SuperBlockStage[SuperBlockStage["Core"] = 0] = "Core";
    SuperBlockStage[SuperBlockStage["English"] = 1] = "English";
    SuperBlockStage[SuperBlockStage["Professional"] = 2] = "Professional";
    SuperBlockStage[SuperBlockStage["Extra"] = 3] = "Extra";
    SuperBlockStage[SuperBlockStage["Legacy"] = 4] = "Legacy";
    SuperBlockStage[SuperBlockStage["New"] = 5] = "New";
    SuperBlockStage[SuperBlockStage["Upcoming"] = 6] = "Upcoming";
})(SuperBlockStage || (exports.SuperBlockStage = SuperBlockStage = {}));
const defaultStageOrder = [
    SuperBlockStage.Core,
    SuperBlockStage.English,
    SuperBlockStage.Professional,
    SuperBlockStage.Extra,
    SuperBlockStage.Legacy
];
function getStageOrder({ showNewCurriculum, showUpcomingChanges }) {
    const stageOrder = [...defaultStageOrder];
    if (showNewCurriculum)
        stageOrder.push(SuperBlockStage.New);
    if (showUpcomingChanges)
        stageOrder.push(SuperBlockStage.Upcoming);
    return stageOrder;
}
exports.getStageOrder = getStageOrder;
// Groups of superblocks in learn map. This should include all superblocks.
exports.superBlockStages = {
    [SuperBlockStage.Core]: [
        SuperBlocks.RespWebDesignNew,
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.FrontEndDevLibs,
        SuperBlocks.DataVis,
        SuperBlocks.RelationalDb,
        SuperBlocks.BackEndDevApis,
        SuperBlocks.QualityAssurance,
        SuperBlocks.SciCompPy,
        SuperBlocks.DataAnalysisPy,
        SuperBlocks.InfoSec,
        SuperBlocks.MachineLearningPy,
        SuperBlocks.CollegeAlgebraPy
    ],
    [SuperBlockStage.English]: [SuperBlocks.A2English],
    [SuperBlockStage.Professional]: [SuperBlocks.FoundationalCSharp],
    [SuperBlockStage.Extra]: [
        SuperBlocks.TheOdinProject,
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.RosettaCode
    ],
    [SuperBlockStage.Legacy]: [
        SuperBlocks.RespWebDesign,
        SuperBlocks.JsAlgoDataStruct,
        SuperBlocks.PythonForEverybody
    ],
    [SuperBlockStage.New]: [],
    [SuperBlockStage.Upcoming]: [
        SuperBlocks.B1English,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython
    ]
};
Object.freeze(exports.superBlockStages);
// when a superBlock is audited, remove it from its language below
// when adding a new language, add all (not audited) superblocks to the object
exports.notAuditedSuperBlocks = {
    [i18n_1.Languages.English]: [],
    [i18n_1.Languages.Espanol]: [
        SuperBlocks.InfoSec,
        SuperBlocks.MachineLearningPy,
        SuperBlocks.CollegeAlgebraPy,
        SuperBlocks.FoundationalCSharp,
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.Chinese]: [
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.ChineseTraditional]: [
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.Italian]: [
        SuperBlocks.FoundationalCSharp,
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.Portuguese]: [
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.Ukrainian]: [
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English
    ],
    [i18n_1.Languages.Japanese]: [
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.German]: [
        SuperBlocks.RelationalDb,
        SuperBlocks.QualityAssurance,
        SuperBlocks.InfoSec,
        SuperBlocks.MachineLearningPy,
        SuperBlocks.CollegeAlgebraPy,
        SuperBlocks.FoundationalCSharp,
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.Swahili]: [
        SuperBlocks.DataVis,
        SuperBlocks.RelationalDb,
        SuperBlocks.BackEndDevApis,
        SuperBlocks.QualityAssurance,
        SuperBlocks.SciCompPy,
        SuperBlocks.DataAnalysisPy,
        SuperBlocks.InfoSec,
        SuperBlocks.MachineLearningPy,
        SuperBlocks.CollegeAlgebraPy,
        SuperBlocks.FoundationalCSharp,
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.TheOdinProject,
        SuperBlocks.RespWebDesign,
        SuperBlocks.FrontEndDevLibs,
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.JsAlgoDataStruct,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody
    ],
    [i18n_1.Languages.Korean]: [
        SuperBlocks.RespWebDesignNew,
        SuperBlocks.JsAlgoDataStruct,
        SuperBlocks.BackEndDevApis,
        SuperBlocks.QualityAssurance,
        SuperBlocks.SciCompPy,
        SuperBlocks.DataAnalysisPy,
        SuperBlocks.InfoSec,
        SuperBlocks.MachineLearningPy,
        SuperBlocks.CollegeAlgebraPy,
        SuperBlocks.FoundationalCSharp,
        SuperBlocks.CodingInterviewPrep,
        SuperBlocks.ProjectEuler,
        SuperBlocks.TheOdinProject,
        SuperBlocks.FrontEndDevLibs,
        SuperBlocks.JsAlgoDataStructNew,
        SuperBlocks.FrontEndDevelopment,
        SuperBlocks.UpcomingPython,
        SuperBlocks.A2English,
        SuperBlocks.B1English,
        SuperBlocks.PythonForEverybody,
        SuperBlocks.DataVis,
        SuperBlocks.RelationalDb,
        SuperBlocks.RosettaCode
    ]
};
Object.freeze(exports.notAuditedSuperBlocks);
function createFlatSuperBlockMap(config) {
    return getStageOrder(config)
        .map(stage => exports.superBlockStages[stage])
        .flat();
}
exports.createFlatSuperBlockMap = createFlatSuperBlockMap;
function getAuditedSuperBlocks({ language = 'english', showNewCurriculum, showUpcomingChanges }) {
    if (!Object.prototype.hasOwnProperty.call(exports.notAuditedSuperBlocks, language)) {
        throw Error(`'${language}' key not found in 'notAuditedSuperBlocks'`);
    }
    const flatSuperBlockMap = createFlatSuperBlockMap({
        showNewCurriculum,
        showUpcomingChanges
    });
    const auditedSuperBlocks = flatSuperBlockMap.filter(superBlock => !exports.notAuditedSuperBlocks[language].includes(superBlock));
    return auditedSuperBlocks;
}
exports.getAuditedSuperBlocks = getAuditedSuperBlocks;
